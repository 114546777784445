














import Vue from 'vue'
import { mapState } from 'vuex'
import { IMethods, IComputed, Property, Crumbs } from '@/types/components/breadcrumb/breadcrumb'

export default Vue.extend<{}, IMethods, IComputed>({
  computed: {
    ...mapState('addyPlusBase', ['corporationsForSideMenu']),
    fullPath() {
      return this.$route.path
    },
    params() {
      // path starts with '/' ie: /addyPlus/:id/settings/administrators
      return this.fullPath.substring(1).split('/')
    },
    corporationId() {
      return this.$route.params.corporationId || ''
    },
    propertyId() {
      return this.$route.params.propertyId || ''
    },
    corporation() {
      return this.corporationsForSideMenu[this.corporationId] || {}
    },
    property() {
      if (!this.corporation?.properties) return {}

      return this.corporation.properties.find((property: Property) => property.id === this.propertyId) || {}
    },
    // Setup the breadcrumb
    // loops through the current path and checks each level for a breadcrumb meta
    // ie: path = /addyPlus/:id/settings
    //     params = ['addyPlus', ':id', 'settings']
    //     1: path = /addyPlus
    //     2: path = /addyPlus/:id
    //     3: path = /addyPlus/:id/settings
    crumbs() {
      const crumbs = {} as Crumbs
      let path = ''
      this.params.forEach((param) => {
        path = `${path}/${param}`
        const route = this.$router.resolve(path).route
        // breadcrumb meta for the route is required in router file src/router/addyPlusRoutes.js
        const title = this.parseTitle(route.meta.breadcrumb)
        if (route.name && title) crumbs[route.name] = { path: route.fullPath, title: this.trimTitle(title), tooltip: this.getTooltip(title) }
      })
      return crumbs
    },
    crumbsLength() {
      return Object.keys(this.crumbs).length
    },
  },
  methods: {
    toPage(path) {
      this.$router.push(path)
    },
    parseTitle(crumbName) {
      const crumbNameExceptions = {
        'Corporation Overview': this.corporation?.name,
        'Property Overview': (this.property as Property)?.nickname,
      }
      return crumbNameExceptions[crumbName as keyof object] || crumbName
    },
    trimTitle(title) {
      return (title?.length > 20) ? `${title.substring(0, 17)}...` : title
    },
    getTooltip(title) {
      return (title?.length > 20) ? title : ''
    },
  },
})
